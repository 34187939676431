import React, { ReactElement } from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { themeV5 } from "~/configs/theme";
import { Trans } from "~/i18n";
import colorTable from "~/shared/ui-kit/constants/colors";
import Icon, { IconNameType } from "~/shared/ui-kit/constants/icons/Icon";
import { IconProps } from "~/shared/ui-kit/constants/icons/Icon/Icon";

const useStyles = makeStyles()(() => ({
  subTitle: {
    fontWeight: 600,
    fontSize: "24px",
    color: colorTable.charcoalBlack,
    lineHeight: "32px",
    marginTop: "16px",
    [themeV5.breakpoints.down(768)]: {
      fontSize: "20px",
      lineHeight: "28px",
    },
  },
  firstGroup: {
    display: "flex",
    alignSelf: "center",
    flexDirection: "column",
    alignItems: "center",
  },
}));

interface TModalTitleGroup {
  title?: string;
  iconName?: IconNameType;
  withBackground?: IconProps["withBackground"] | null;
}

export const ModalTitleGroup = ({ title, iconName, withBackground }: TModalTitleGroup): ReactElement => {
  const { classes } = useStyles();
  const background = {
    padding: "14px",
    borderRadius: "16px",
    backgroundColor: colorTable.transparent,
    width: "68px",
    height: "68px",
    ...withBackground,
  };

  return (
    <Box className={classes.firstGroup}>
      <Icon name={iconName} size={40} withBackground={background} />
      <Typography className={classes.subTitle}>
        <Trans i18nKey={title} />
      </Typography>
    </Box>
  );
};
