import { createElement, ReactHTML, ReactNode } from "react";
import cn from "classnames";
import "./reset.css";

import { useStyleTypography } from "~/shared/ui-kit/constants/typography";

export type TTypographyFont = keyof ReturnType<typeof useStyleTypography>["classes"];

export type BaseTypographyProps<T extends keyof ReactHTML> = React.ComponentProps<T> & {
  tag?: keyof ReactHTML;
  children: ReactNode | ReactNode[];
  fontType?: TTypographyFont;
  className?: string;
};

/**
 * @param fontType - dsktp - Desktop(fontSize, lineHeight), mb - mobile(768)(fontSize, lineHeight), fw - fontWeight, pstfx - postfix (default: 400, _medium: 500, _semiBold: 600, _bold: 700)
 ** caption (dsktp: 14,16 | mb: 14,16 | pstfx: _semiBold)
 ** subhead: (dsktp: 14,20 | mb: 14,20 | pstfx: _medium, _semiBold)
 ** text: (dsktp: 16,24 | mb: 16,24 | pstfx: _medium, _semiBold)
 ** headline: (dsktp: 20,28 | mb: 20,28 | pstfx: _medium, _semiBold)
 ** title_2: (dsktp: 24,32 | mb: 20,28 | pstfx: _medium, _semiBold, _bold)
 ** title_1: (dsktp: 32,40 | mb: 24,32 | pstfx: _bold)
 */

export default function <T extends keyof ReactHTML>({
  tag = "p",
  className,
  children,
  fontType,
  style,
  onClick,
}: BaseTypographyProps<T>) {
  const { classes } = useStyleTypography();
  return createElement(tag, { className: cn(classes[fontType ?? "default"], className), style, onClick }, children);
}
