import { createDomain, forward, sample } from "effector";
import { createForm } from "effector-forms";
import { createGate } from "effector-react";
import { createReEffect } from "effector-reeffect";
import { persist } from "effector-storage/local";
import JwtDecode from "jwt-decode";
import { combineEvents } from "patronum";

import { setAppDynamicsUserData } from "~/configs/appdynamics";
import history from "~/configs/history";
import store from "~/configs/store";
import { links } from "~/constants/enums";
import { notDigitPattern } from "~/constants/regexp";
import {
  CountryCode,
  KeycloakResponseErrorCode,
  LoginNoOtpResponse,
  LoginOtpResponse,
  LoginRequest,
  LoginSessionsResponse,
  LoginWithOtpCodeRequest,
} from "~/entities/keycloak";
import { authApi } from "~/entities/keycloak/api";
import { USER_AUTH_BLOCK } from "~/entities/productBuy/types";
import { t } from "~/i18n";
import { autofillOtpCode } from "~/lib/autofillOtpCode";
import { some } from "~/lib/operations/some";
import { maxLengthPassword, rules } from "~/lib/validation/rules";
import { appBootRequestAction } from "~/modules/app/actions";
import { clientCardRequestAction } from "~/modules/client-card/actions";
import { currentClientModel } from "~/modules/current-client-model";
import { loginErrorsEnum, LoginLocks, LoginStepsTypes, ResetPasswordTypes } from "~/modules/keycloak/login-model/types";
import { logoutModel } from "~/modules/keycloak/logout-model";
import { notificationModel } from "~/modules/notification-model";
import { replenishmentNotificationModalModel } from "~/modules/replenishment-brokerage-account-model";

const loginFx = createReEffect<LoginRequest, LoginSessionsResponse, any>({ handler: authApi.login });

const loginWithOtpCodeFx = createReEffect<LoginWithOtpCodeRequest, LoginOtpResponse | LoginNoOtpResponse, any>({
  handler: authApi.loginWithOtpCode,
});

const getCountryCodeFx = createReEffect<any, CountryCode[], any>({ handler: authApi.getCountryCode });

const loginDomain = createDomain("login");
const gate = createGate({ domain: loginDomain });

const onBack = loginDomain.createEvent();
const loginToAppDLoginForm = loginDomain.createEvent();
const loginToApp = loginDomain.createEvent();
const login = loginDomain.createEvent();
const loginDLoginForm = loginDomain.createEvent();
const onLogin = loginDomain.createEvent<{ access_token: string; refresh_token: string }>();
const setUserLogin = loginDomain.createEvent<string>();
const setTokens = loginDomain.createEvent<{ access_token: string; refresh_token: string }>();
const checkTokens = loginDomain.createEvent();
const getNewOtp = loginDomain.createEvent();
const getNewOtpDlogin = loginDomain.createEvent();
const setRecaptchaValue = loginDomain.createEvent<string>();
const showOtpForm = loginDomain.createEvent<boolean>();
const loginShowError = loginDomain.createEvent<boolean>();
const toggleLoginScreen = loginDomain.createEvent<boolean>();
const isOtpError = loginDomain.createEvent<boolean>();
const setStep = loginDomain.createEvent<LoginStepsTypes>();
const setCountryCode = loginDomain.createEvent<string>();
const showReCAPTCHA = loginDomain.createEvent<boolean>();

const getAvailableResetPasswordTypes = loginDomain.createEvent();
const setResetPasswordType = loginDomain.createEvent<ResetPasswordTypes | null>();
const clearResetPasswordType = loginDomain.createEvent();

const $reCAPTCHaIsVisible = loginDomain.createStore(false);
$reCAPTCHaIsVisible.on(showReCAPTCHA, (_, show) => show);

const $otpFormIsVisible = loginDomain.createStore(false);
$otpFormIsVisible.on(showOtpForm, (_, showOtp) => showOtp).reset(loginWithOtpCodeFx.doneData);

const $loginHasError = loginDomain.createStore(false);
$loginHasError.on(loginShowError, (_, showError) => showError);
$loginHasError.reset(toggleLoginScreen);
$loginHasError.reset(gate.close);

const $isLogin = loginDomain.createStore(true);
$isLogin.on(logoutModel.setLogin, (_, isLogin) => isLogin);

const $recaptchaValue = loginDomain.createStore<string>("service is unavailable");
$recaptchaValue.on(setRecaptchaValue, (_, data) => data);

const $resetPasswordType = loginDomain.createStore<null | ResetPasswordTypes>(null);
$resetPasswordType.reset(clearResetPasswordType);
$resetPasswordType.on(setResetPasswordType, (_, resetType) => resetType);

const $tokens = loginDomain.createStore<{ access_token: string; refresh_token: string }>({
  access_token: "",
  refresh_token: "",
});

$tokens.on(onLogin, (_, tokens) => tokens);
$tokens.on(setTokens, (_, tokens) => tokens);

const $showDLogin = loginDomain.createStore(false);
$showDLogin.on(toggleLoginScreen, (_, show) => show);

const $otpError = loginDomain.createStore<boolean>(false);
$otpError.reset(onBack);
$otpError.on(isOtpError, (_, status) => status);

const $currentStep = loginDomain.createStore<LoginStepsTypes>(LoginStepsTypes.LOGIN);
$currentStep.on(setStep, (_, step) => step);

forward({ from: gate.open, to: getCountryCodeFx });

const $countryCodes = loginDomain.createStore<CountryCode[]>([]);
$countryCodes.on(getCountryCodeFx.doneData, (_, data) => data);

const $codeIso = loginDomain.createStore<string>("");
$codeIso.on(setCountryCode, (_, code) => code);

const $messageDescription = loginDomain.createStore<string>("");
$messageDescription.reset([getNewOtp, getNewOtpDlogin]);
$messageDescription.on(loginFx.doneData, (_, data) => data.message_description);

persist({ store: $tokens, key: "tokens" });

const $userLogin = loginDomain.createStore<string>("");
$userLogin.on(setUserLogin, (_, data) => data);

const formPhoneNumber = createForm({
  fields: {
    phoneNumber: {
      init: "",
      rules: [rules.phoneNumberIsField($countryCodes, $codeIso)],
    },
    password: {
      init: "",
      rules: [rules.required(), rules.less(maxLengthPassword)],
    },
  },
  domain: loginDomain,
  validateOn: ["submit"],
});

const form = createForm({
  fields: {
    dLogin: {
      init: "d-",
      rules: [
        {
          name: "login is empty",
          validator: (login: string) => login.trim() !== "" || login.trim() !== "d-",
          errorText: "VALIDATION.d-login.incorrect",
        },
        {
          name: "login is not valid",
          validator: (login: string) => /^[Dd]-\d{6,7}?$/.test(login),
          errorText: "VALIDATION.d-login.incorrect",
        },
      ],
    },
    passwordDlogin: {
      init: "",
      rules: [rules.required(), rules.less(maxLengthPassword)],
    },
    isOtpRequired: {
      init: false,
    },
  },
  domain: loginDomain,
  validateOn: ["submit"],
});

forward({ from: gate.close, to: formPhoneNumber.reset });

const $loginPending = loginDomain.createStore<boolean>(false);
const setLoginPending = loginDomain.createEvent<boolean>();
$loginPending.on(setLoginPending, (_, data) => data);
$loginPending.on(loginFx.pending, (_, payload) => payload);

const $OtpPending = loginDomain.createStore<boolean>(false);
const setOtpPending = loginDomain.createEvent<boolean>();
$OtpPending.on(setOtpPending, (_, data) => data);
$OtpPending.on(loginWithOtpCodeFx.pending, (_, payload) => payload);

sample({
  source: $countryCodes,
  clock: getCountryCodeFx.doneData,
  fn: (country) => {
    const countryPreview = country.find((item) => item.codeIso === "643");
    return countryPreview?.codeIso ?? "";
  },
  target: $codeIso,
});

sample({
  source: [$countryCodes, $codeIso] as const,
  clock: [getCountryCodeFx.doneData, formPhoneNumber.reset],
  fn: ([country, codeIso]) => {
    const countryPreview = country.find((item) => item.codeIso === "643");
    const chosenCountry = country.find((item) => item.codeIso === codeIso);

    return chosenCountry?.phoneCode || (countryPreview?.phoneCode ?? "");
  },
  target: formPhoneNumber.fields.phoneNumber.onChange,
});

const otpForm = createForm({
  fields: {
    otp: {
      init: "",
      rules: (_value: string, form) =>
        form.isOtpRequired
          ? [
              {
                name: "otp is empty",
                validator: (value: string) => value.trim() !== "",
                errorText: "VALIDATION.required-field",
              },
              {
                name: "otp length",
                validator: (value: string) => value.trim().length === 6,
              },
            ]
          : [],
    },
  },
  domain: loginDomain,
  validateOn: ["submit"],
});

$otpError.reset(otpForm.fields.otp.onChange);

const $buttonEnterIsDisabledForDLogin = some({
  stores: [
    form.$values.map((v) => {
      return !v.passwordDlogin.length;
    }),
  ],
});

forward({ from: gate.close, to: form.reset });
forward({
  from: [onBack, loginWithOtpCodeFx.doneData],
  to: [
    form.fields.isOtpRequired.reset,
    otpForm.fields.otp.reset,
    formPhoneNumber.fields.phoneNumber.reset,
    formPhoneNumber.fields.password.reset,
  ],
});

onBack.watch(() => {
  history.push(links.login);
  showOtpForm(false);
  loginShowError(false);
});

const loginWithPhoneNumber = combineEvents({
  events: [login, formPhoneNumber.formValidated],
});

sample({
  source: [formPhoneNumber.$values, $recaptchaValue, $countryCodes, $codeIso] as const,
  clock: loginWithPhoneNumber,
  fn: ([values, recaptcha, countryCodes, codeIso]): LoginRequest => {
    const countryCode = countryCodes.find((codePhone) => codePhone.codeIso === codeIso);
    const phoneNumber = "+" + values.phoneNumber.replace(notDigitPattern, "");

    return {
      username: phoneNumber,
      password: values.password,
      recaptcha,
      region: countryCode?.charCode2,
    };
  },
  target: loginFx,
});

sample({
  //Запрос для получения нового кода
  source: [formPhoneNumber.$values, $recaptchaValue, $countryCodes, $codeIso] as const,
  clock: getNewOtp,
  fn: ([values, recaptcha, countryCodes, codeIso]): LoginRequest => {
    const countryCode = countryCodes.find((codePhone) => codePhone.codeIso === codeIso);
    const phoneNumber = "+" + values.phoneNumber.replace(notDigitPattern, "");

    return {
      username: phoneNumber,
      password: values.password,
      recaptcha,
      region: countryCode?.charCode2,
    };
  },
  target: loginFx,
});

//дублирование кода из-за разных форм Вход по номеру телефона
sample({
  source: [formPhoneNumber.$values, $countryCodes, $codeIso, $messageDescription, $recaptchaValue] as const,
  clock: loginToApp,
  fn: ([values, countryCodes, codeIso, messageDescription, recaptcha]): LoginWithOtpCodeRequest => {
    const otp = otpForm.$values.getState().otp;
    const countryCode = countryCodes.find((codePhone) => codePhone.codeIso === codeIso);
    const phoneNumber = "+" + values.phoneNumber.replace(notDigitPattern, "");

    return {
      username: phoneNumber,
      password: values.password,
      session_code: otp,
      session_id: messageDescription,
      recaptcha,
      region: countryCode?.charCode2,
    };
  },
  target: loginWithOtpCodeFx,
});

const loginWithDlogin = combineEvents({
  events: [form.formValidated, loginDLoginForm],
});

sample({
  source: [form.$values, $recaptchaValue, $countryCodes, $codeIso] as const,
  clock: loginWithDlogin,
  fn: ([values, recaptcha]): LoginRequest => {
    return {
      username: values.dLogin,
      password: values.passwordDlogin,
      recaptcha,
      region: "RU",
    };
  },
  target: loginFx,
});

loginFx.doneData.watch((data) => {
  showOtpForm(true);
  autofillOtpCode(data?.debug_data?.code, () => otpForm.fields.otp.set(data.debug_data.code));
});
//новый логин

//дублирование кода из-за разных форм Вход по d-login
sample({
  source: [form.$values, $messageDescription, $recaptchaValue, $countryCodes, $codeIso] as const,
  clock: loginToAppDLoginForm,
  fn: ([values, messageDescription, recaptcha, countryCodes, codeIso]): LoginWithOtpCodeRequest => {
    const otp = otpForm.$values.getState().otp;
    const countryCode = countryCodes.find((codePhone) => codePhone.codeIso === codeIso) || { charCode2: "RU" };
    return {
      username: values.dLogin,
      password: values.passwordDlogin,
      session_code: otp,
      session_id: messageDescription,
      recaptcha,
      region: countryCode?.charCode2,
    };
  },
  target: loginWithOtpCodeFx,
});

loginWithOtpCodeFx.fail.watch(() => {
  logoutModel.setLogin(false);
  setUserLogin("");
});

sample({
  //Запрос для получения нового кода
  source: [form.$values, $recaptchaValue, $countryCodes, $codeIso] as const,
  clock: getNewOtpDlogin,
  fn: ([values, recaptcha, countryCodes, codeIso]): LoginRequest => {
    const countryCode = countryCodes.find((codePhone) => codePhone.codeIso === codeIso);
    return {
      username: values.dLogin,
      password: values.passwordDlogin,
      recaptcha,
      region: countryCode?.charCode2,
    };
  },
  target: loginFx,
});

sample({
  //Логинемся
  source: loginWithOtpCodeFx.doneData,
  fn: (data): { access_token: string; refresh_token: string } => {
    const response = data as LoginOtpResponse;
    //@ts-ignore
    setUserLogin(JwtDecode(response.access_token).additional_login);
    return { access_token: response.access_token, refresh_token: response.refresh_token };
  },
  target: onLogin,
});

onLogin.watch(() => {
  logoutModel.setLogin(true);
  replenishmentNotificationModalModel.checkServiceActivationStatus();
  setRecaptchaValue("service is unavailable");
  store.dispatch(clientCardRequestAction());
  currentClientModel.getCurrentClientFx.doneData.watch((data) => {
    setAppDynamicsUserData({
      login: data?.dlogin,
    });
  });
});

checkTokens.watch(() => {
  store.dispatch(appBootRequestAction());
  const tokens = localStorage.getItem("tokens");
  if (tokens !== null) {
    logoutModel.setLogin(true);
    store.dispatch(clientCardRequestAction());
  } else {
    logoutModel.setLogin(false);
    setUserLogin("");
  }
});

sample({
  source: loginFx.fail,
  clock: loginFx.fail,
  filter: (err) => err.error.error.response.data.error_description === "Account disabled",
  // target: checkResetType,
  target: notificationModel.error.prepend(() => t("KEYCLOAK_ERRORS.UNBLOCK_USER")),
});

loginFx.fail.watch((err) => {
  if (
    err.error.error.response.data.error === loginErrorsEnum.invalidGrant ||
    err.error.error.response.data.error === loginErrorsEnum.invalidUsername
  ) {
    loginShowError(true);
  }
  if (err.error.error.response.data.error === KeycloakResponseErrorCode.IncorrectOtp) {
    notificationModel.error(t("KEYCLOAK_ERRORS.INCORRECT_OTP"));
  }
  if (
    err.error.error.response.data.error_description === LoginLocks.FIRST_LOCK ||
    err.error.error.response.data.error_description === USER_AUTH_BLOCK
  ) {
    setStep(LoginStepsTypes.TEMPORARILY_BLOCKED_ONE_HOUR);
  }
  if (
    err.error.error.response.data.error_description === LoginLocks.SECOND_LOCK ||
    err.error.error.response.data.error_description === USER_AUTH_BLOCK
  ) {
    setStep(LoginStepsTypes.TEMPORARILY_BLOCKED_ONE_HOUR);
  }
  if (
    err.error.error.response.data.error_description === LoginLocks.THIRD_LOCK ||
    err.error.error.response.data.error_description === USER_AUTH_BLOCK
  ) {
    setStep(LoginStepsTypes.TEMPORARILY_BLOCKED_24_HOURS);
  }
  if (
    err.error.error.response.data.error_description === LoginLocks.PERMANENT_LOCK ||
    err.error.error.response.data.error_description === USER_AUTH_BLOCK
  ) {
    setStep(LoginStepsTypes.BLOCKED_USER);
  }
  getAvailableResetPasswordTypes();
});

loginFx.fail.watch((err) => {
  if (err.error.error.response.data.error.description === "Constant password is not set up") {
    history.push("");
  }
});

loginWithOtpCodeFx.fail.watch((err) => {
  if (err.error.error.response.data.error === "wrong_otp") {
    isOtpError(true);
  }
  if (
    err.error.error.response.data.error_description === LoginLocks.FIRST_LOCK ||
    err.error.error.response.data.error_description === USER_AUTH_BLOCK
  ) {
    setStep(LoginStepsTypes.TEMPORARILY_BLOCKED_ONE_HOUR);
    showOtpForm(false);
  }
  if (
    err.error.error.response.data.error_description === LoginLocks.SECOND_LOCK ||
    err.error.error.response.data.error_description === USER_AUTH_BLOCK
  ) {
    setStep(LoginStepsTypes.TEMPORARILY_BLOCKED_ONE_HOUR);
    showOtpForm(false);
  }
  if (
    err.error.error.response.data.error_description === LoginLocks.THIRD_LOCK ||
    err.error.error.response.data.error_description === USER_AUTH_BLOCK
  ) {
    setStep(LoginStepsTypes.TEMPORARILY_BLOCKED_24_HOURS);
    showOtpForm(false);
  }
  if (err.error.error.response.data.error_description === LoginLocks.PERMANENT_LOCK) {
    setStep(LoginStepsTypes.BLOCKED_USER);
    showOtpForm(false);
  }
});

loginWithOtpCodeFx.fail.watch((err) => {
  if (
    err.error.error.response.data.error === "temporary_password" ||
    err.error.error.response.data.error === "REQUIRED_SETUP_SECURITY_QUESTION"
  ) {
    setStep(LoginStepsTypes.TEMPORARILY_PASSWORD);
    showOtpForm(false);
  }
});

loginShowError.watch((flag) => {
  if (flag) {
    notificationModel.error(t("LOGIN.ERRORS.invalid-user-credentials"));
  }
});

export const loginModel = {
  form,
  loginModelGate: gate,
  onBack,
  $loginHasError,
  checkTokens,
  setResetPasswordType,
  clearResetPasswordType,
  getNewOtp,
  loginToApp,
  loginToAppDLoginForm,
  $loginPending,
  setLoginPending,
  $OtpPending,
  setOtpPending,
  $isLogin,
  toggleLoginScreen,
  $showDLogin,
  $resetPasswordType,
  $currentStep,
  $tokens,
  onLogin,
  login,
  loginDLoginForm,
  setRecaptchaValue,
  showOtpForm,
  $recaptchaValue,
  $otpError,
  getNewOtpDlogin,
  $buttonEnterIsDisabledForDLogin: $buttonEnterIsDisabledForDLogin,
  formPhoneNumber,
  $otpFormIsVisible,
  setStep,
  $countryCodes,
  setCountryCode,
  $codeIso: $codeIso,
  $countryPending: getCountryCodeFx.pending,
  otpForm,
  $userLogin,
  setUserLogin,
  showReCAPTCHA,
  $reCAPTCHaIsVisible,
  loginShowError,
};
