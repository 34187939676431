import React, { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { UnauthorizedLayoutNew } from "~/app/layouts/UnauthorizedLayoutNew";
import { RouteT } from "~/app/router";
import { links, routes } from "~/constants/enums";
import Loader from "~/features/Loader/Loader";
import { useScrollToTop } from "~/lib/hooks/useScrollToTop";

const LoginNew = lazy(() => import("~/pages/UnauthAndBlocked/Login"));
const Registration = lazy(() => import("~/pages/UnauthAndBlocked/Registration"));
const ForgotPassword = lazy(() => import("~/pages/UnauthAndBlocked/ForgotPassword"));
const NotAuthBlocked = lazy(() => import("~/pages/NotAuthBlocked"));
const UnBlockingUser = lazy(() => import("~/pages/UnauthAndBlocked/UnBlockingUser"));

export const ALL_UNAUTH_ROUTES = [
  links.registrationFirstStep,
  links.registrationDoc,
  links.registrationSecondStep,
  links.registrationThirdStep,
  links.registrationFailed,
  links.registrationSMS,
  links.registrationSuccess,
  routes.blocked,
  routes.forgotPassword,
  routes.unblocking,
  routes.login,
];

export const unauthorizedRoutes: RouteT[] = [
  { path: routes.registrationNew, component: <Registration /> },
  {
    path: routes.forgotPassword,
    component: <ForgotPassword />,
  },
  {
    path: routes.unblocking,
    component: <UnBlockingUser />,
  },
  {
    path: routes.login,
    component: <LoginNew />,
  },
  { path: routes.blocked, component: <NotAuthBlocked /> },
];

export const NotAuthRoutes = () => {
  useScrollToTop();

  return (
    <Routes>
      <Route element={<UnauthorizedLayoutNew />}>
        {unauthorizedRoutes.map((route) => (
          <Route
            path={route.path}
            key={route.path}
            element={<Suspense fallback={<Loader />}>{route.component} </Suspense>}
          />
        ))}
      </Route>

      <Route path="*" element={<Navigate to={links.login} replace />} />
    </Routes>
  );
};
