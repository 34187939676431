import { makeStyles } from "tss-react/mui";

import { Theme } from "~/configs/theme";
import colorTable from "~/shared/ui-kit/constants/colors";

export const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    minHeight: "100%",
    position: "relative",
    background: colorTable.lightGrey_80,
  },
  containerWrapper: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  header: {
    background: colorTable.lightGrey_80,
    [theme.breakpoints.down(900)]: {
      background: colorTable.charcoalBlack,
    },
  },
  container: {
    display: "flex",
    flex: "1 0 auto",
    justifyContent: "center",
    flexGrow: 1,
    padding: "20px 10px 56px 10px",
    alignItems: "center",
    zIndex: 3,
    borderRadius: "24px",
    flexDirection: "column",
    width: "calc(100% - 160px)",
    margin: "25px auto 0 auto",
    [theme.breakpoints.down(1200)]: {
      width: "calc(100% - 80px)",
    },
    [theme.breakpoints.down(900)]: {
      width: "100%",
      padding: "0px",
      margin: "0px",
      borderRadius: "0",
    },
  },
  footerWrapper: {
    width: "100%",
    zIndex: 10,
    paddingTop: "40px",
    background: colorTable.lightGrey_80,
  },
}));
