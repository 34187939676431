import { AxiosError } from "axios";
import { Effect, Event, Store } from "effector";
import { Gate } from "effector-react";

import { DocumentListResponse } from "~/entities/documents/api";

export enum DocsType {
  w8ben = "W8_BEN",
}
export interface IDocumentsListModel {
  gate: Gate<any>;
  deleteDocId: Event<void>;
  addDoc: Event<any>;
  $documentsListUKF: Store<DocumentListResponse["content"]>;
  $brokerDocsUKF: Store<DocumentListResponse["content"]>;
  $archiveBrokerUKF: Store<DocumentListResponse["content"]>;
  $assetDocsUKF: Store<DocumentListResponse["content"]>;
  $archiveAssetUKF: Store<DocumentListResponse["content"]>;
  $documentsListPendingUKF: Store<boolean>;
  setIdDocUKF: Event<string>;
  $docUKF: Store<any>;
  $getDocumentsPendingUKF: Store<boolean>;
  getDocumentsListUKFFx: Effect<void, DocumentListResponse, AxiosError>;
  getDocumentUKFFx: Effect<any, any, AxiosError>;
  $docW8DownloadLoading: Store<boolean>;
  downloadW8DocBtnEvt: Event<void>;
}
