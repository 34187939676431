export type ApplicationRequest = {
  account?: string;
  agreementNumber?: string;
  investmentSum?: number;
  offerId?: string;
  confirmationFlag: boolean;
  viewedFiles: (string | undefined)[];
  priceFactor?: number;
  accountCurrency?: string;
  locationUuid?: string;
  edoAgreementNumber: string;
};

export type ApplicationDocumentRequest = {
  applicationId?: number;
  view: "short" | "full" | "null" | "empty";
};

export type Document = {
  file: string;
  formTemplate: string;
  meta: {
    description: string;
    filename: string;
    open: boolean;
    readable: boolean;
  };
  uri: {
    absolute: boolean;
    authority: string;
    fragment: string;
    host: string;
    opaque: boolean;
    path: string;
    port: number;
    query: string;
    rawAuthority: string;
    rawFragment: string;
    rawPath: string;
    rawQuery: string;
    rawSchemeSpecificPart: string;
    rawUserInfo: string;
    scheme: string;
    schemeSpecificPart: string;
    userInfo: string;
  };
  url: {
    authority: string;
    defaultPort: number;
    file: string;
    host: string;
    path: string;
    port: number;
    protocol: string;
    query: string;
    ref: string;
    userInfo: string;
  };
};

export type ApplicationDocumentResponse = {
  code: string;
  documents: Document[];
  status: string;
};

export type ApplicationOtpCheckRequest = {
  applicationId?: number;
  otp?: number;
};

export type ApplicationOtpCheckResponse = {
  message: string;
};
