import React, { lazy, Suspense, useLayoutEffect, useReducer } from "react";
import { Router as MainRouter } from "react-router-dom";
import { useUnit } from "effector-react";
import { Update } from "history";

import { InvalidBrowser } from "~/app/router/components/InvalidBrowser";
import { ALL_UNAUTH_ROUTES, NotAuthRoutes } from "~/app/router/components/NotAuthRoutes";
import { TechnicalWorks } from "~/app/router/components/TechnicalWorks";
import history from "~/configs/history";
import { links, notForRedirectPath, routes } from "~/constants/enums";
import Loader from "~/features/Loader";
import { technicalWorksModel } from "~/modules/app/technical-works-model";
import { checkBrowserVersionModel } from "~/modules/check-browser-version";
import { currentClientModel } from "~/modules/current-client-model";
import { loginModel } from "~/modules/keycloak/login-model";
const AuthRoutes = lazy(() => import("~/app/router/components/AuthRoutes"));

export const Router = () => {
  const { $isValidBrowser, checkBrowserEvent } = checkBrowserVersionModel;
  const { $isLogin } = loginModel;
  const { $isTechnicalWorks } = technicalWorksModel;
  const isLogin = useUnit($isLogin);
  const isTechnicalWorks = useUnit($isTechnicalWorks);
  const isValidBrowser = useUnit($isValidBrowser);

  useLayoutEffect(() => {
    checkBrowserEvent();
  }, []);

  const renderRoutes = () => {
    if (isTechnicalWorks) return <TechnicalWorks />;
    if (!isValidBrowser) return <InvalidBrowser />;
    if (isLogin) {
      return (
        <Suspense fallback={<Loader />}>
          <AuthRoutes />
        </Suspense>
      );
    }
    return <NotAuthRoutes />;
  };

  return <HistoryRouter>{renderRoutes()}</HistoryRouter>;
};

const reducer = (_: Update, action: Update) => action;

type HistoryRouterProps = {
  children: React.ReactNode;
};

export const HistoryRouter = ({ children }: HistoryRouterProps) => {
  const { $currentClient } = currentClientModel;
  const currentClient = useUnit($currentClient);

  const [state, dispatch] = useReducer(reducer, {
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => {
    if (
      !ALL_UNAUTH_ROUTES.find((el) => el === history.location.pathname) &&
      !currentClient &&
      history.location.pathname !== routes.notAvailable &&
      history.location.pathname !== routes.invalidBrowser
    ) {
      if (notForRedirectPath.includes(history.location?.pathname as routes | links)) {
        sessionStorage.setItem("redirectPath", links.operationsAssets);
      } else {
        sessionStorage.setItem("redirectPath", history.location?.pathname);
      }
    }
  }, [history.location?.pathname]);

  useLayoutEffect(() => history.listen(dispatch), []);

  return (
    <MainRouter navigationType={state.action} location={state.location} navigator={history}>
      {children}
    </MainRouter>
  );
};
