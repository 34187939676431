import { createDomain } from "effector";
import UAParser from "ua-parser-js";

const checkBrowserVersionDomain = createDomain("check-browser-version");

const $isValidBrowser = checkBrowserVersionDomain.createStore(true);

const checkBrowserEvent = checkBrowserVersionDomain.createEvent();
const setIsValidBrowser = checkBrowserVersionDomain.createEvent<boolean>();
const setBrowserError = checkBrowserVersionDomain.createEvent<{ title?: string; browser: string; version: string }>();

$isValidBrowser.on(setIsValidBrowser, (_, payload) => payload);

const $message = checkBrowserVersionDomain.createStore<{
  title: string;
  browser: string;
  version: string;
}>({ title: "ERRORS.invalid_browser_title", browser: "", version: "" });

$message.on(setBrowserError, (_, data) => {
  return { title: data.title ?? "ERRORS.invalid_browser_title", browser: data?.browser, version: data?.version };
});

checkBrowserEvent.watch(() => {
  const parser = new UAParser();
  const parserResults = parser.getResult();

  const browserVersionNumber = Number(parserResults.browser.version?.split(".").slice(0, 2).join("."));

  console.log(parserResults);
  console.log(browserVersionNumber);

  if (parserResults.browser.name === "Edge" || parserResults.browser.name === "Chrome") {
    if (browserVersionNumber < 119) {
      setBrowserError({ browser: parserResults.browser.name, version: "119" });
      setIsValidBrowser(false);
    } else {
      setIsValidBrowser(true);
    }
  } else if (parserResults.browser.name === "Safari" || parserResults.browser.name === "Mobile Safari") {
    if (browserVersionNumber < 17.4) {
      setBrowserError({ browser: parserResults.browser.name, version: "17.4" });
      setIsValidBrowser(false);
    } else {
      setIsValidBrowser(true);
    }
  } else if (parserResults.browser.name === "IE") {
    setBrowserError({
      title: "ERRORS.invalid_browser_title_not_work",
      browser: parserResults.browser.name,
      version: "17.4",
    });
    setIsValidBrowser(false);
  } else if (parserResults.browser.name === "Firefox") {
    if (browserVersionNumber < 121) {
      setBrowserError({ browser: parserResults.browser.name, version: "121" });
      setIsValidBrowser(false);
    } else {
      setIsValidBrowser(true);
    }
  } else if (parserResults.browser.name === "Opera" || parserResults.browser.name === "Opera Touch") {
    if (parserResults.os.name === "Android") {
      if (browserVersionNumber < 79) {
        setBrowserError({ browser: parserResults.browser.name, version: "79" });
        setIsValidBrowser(false);
      } else {
        setIsValidBrowser(true);
      }
    } else if (parserResults.os.name === "iOS") {
      if (browserVersionNumber < 5) {
        setBrowserError({ browser: parserResults.browser.name, version: "5" });
        setIsValidBrowser(false);
      } else {
        setIsValidBrowser(true);
      }
    } else {
      if (browserVersionNumber < 105) {
        setBrowserError({ browser: parserResults.browser.name, version: "105" });
        setIsValidBrowser(false);
      } else {
        setIsValidBrowser(true);
      }
    }
  } else {
    setIsValidBrowser(true);
  }
});

export const checkBrowserVersionModel = {
  checkBrowserEvent,
  $isValidBrowser,
  $message,
};
