import React, { ReactNode } from "react";
import { Outlet } from "react-router-dom";
import { Box, Grid } from "@mui/material";

import { Footer } from "~/features/Footer/Footer";
import { useChangeHeaderTheme } from "~/lib/hooks/useChangeHeaderTheme";
import colorTable from "~/shared/ui-kit/constants/colors";
import { WithNotificationModal } from "~/widgets/WithNotificationModal";
import ViewportHeader from "../BaseLayout/components/ViewportHeader";
import { useStyles } from "./styles";

type TUnauthorizedLayoutNewProps = {
  children?: ReactNode;
};

export const UnauthorizedLayoutNew = ({ children }: TUnauthorizedLayoutNewProps) => {
  const { classes } = useStyles();

  useChangeHeaderTheme(colorTable.charcoalBlack, "(max-width: 900px)");

  return (
    <WithNotificationModal>
      <Grid container direction="column" alignItems="stretch" wrap="nowrap" className={classes.root}>
        <Box className={classes.containerWrapper}>
          <ViewportHeader unAuthorized={true} className={classes.header} />
          <Grid item className={classes.container}>
            {children || <Outlet />}
          </Grid>
          <Grid className={classes.footerWrapper}>
            <Footer />
          </Grid>
        </Box>
      </Grid>
    </WithNotificationModal>
  );
};
